import React from 'react';
import { graphql } from 'gatsby';

//Components
import Layout from '../components/layout';
import SEO from '../components/seo';

//Template
import ContactPageTemplate from '../templates/pages/contact';

const ContactPage = ({ data, pageContext }) => {
  return (
    <Layout lang={pageContext.langKey}>
      <SEO
        title={data.pagesJson.languages.en.metaData.title}
        description={data.pagesJson.languages.en.metaData.description}
      />

      <ContactPageTemplate
        data={data.pagesJson.languages.en}
        lang="en"
        contactPage
      />
    </Layout>
  );
};

export const CONTACT_PAGE_EN_QUERY = graphql`
  query CONTACT_PAGE_EN_QUERY {
    pagesJson(pageKey: { eq: "contact" }) {
      languages {
        en {
          metaData {
            title
            description
          }
          intro {
            title
            text
            media {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            thumbnailVideo
            fullVideo
          }
          contacts {
            addresses {
              address
            }
            phones {
              phone
            }
            faxes {
              fax
            }
            emails {
              email
            }
          }
        }
      }
    }
  }
`;

export default ContactPage;
